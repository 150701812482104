import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-secondary">
      <div className="w-full px-4 xl:px-6 pt-14 max-sm:pt-10 pb-20 max-sm:pb-12 max-w-[1367px] mx-auto">
        <div className="w-full flex flex-wrap max-lg:gap-y-6">
          <div className="w-[35%] max-lg:w-[50%] max-sm:w-[100%] px-2">
            <Link to="/">
              <img
                className="2xl:w-[300px] max-sm:w-[160px]"
                src="/assets/logoBlue.svg"
                alt="Shipitor Logo"
              />
            </Link>
            <p className="text-sec_text 2xl:text-xl mt-4 pt-3">
              © REECARD LLC {new Date().getFullYear()}. All rights reserved.{" "}
            </p>
          </div>
          <div className="w-[20%] max-lg:w-[50%] max-sm:w-[100%] px-2">
            <p className="text-xl font-[500] text-black mb-5">Quick Links</p>
            <Link
              to="/"
              className="block hover:text-primary 2xl:text-lg mb-3 text-sec_text"
            >
              Home
            </Link>
            <Link
              to="/dashboard"
              className="block hover:text-primary 2xl:text-lg mb-3 text-sec_text"
            >
              Dashboard
            </Link>
            <a
              href="https://docs.shipitor.com/"
              target="_blank"
              className="block hover:text-primary 2xl:text-lg mb-3 text-sec_text"
            >
              Documentation
            </a>
          </div>
          <div className="w-[20%] max-lg:w-[50%] max-sm:w-[100%] px-2">
            <p className="text-xl font-[500] text-black mb-5">Legal</p>
            <Link
              to="/terms"
              className="block hover:text-primary 2xl:text-lg mb-3 text-sec_text"
            >
              Terms of Service
            </Link>
            <Link
              to="/privacy"
              className="block hover:text-primary 2xl:text-lg mb-3 text-sec_text"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="w-[25%] max-lg:w-[50%] max-sm:w-[100%] px-2">
            <p className="text-xl font-[500] text-black mb-5">Join Our Newsletter</p>
            <form
              onSubmit={e => e.preventDefault()}
              className="relative w-full max-w-[300px]"
            >
              <input
                type="email"
                className="bg-[#DDDDDD] rounded-lg text-[#898B91] w-full py-[15px] pl-[12px] pr-8"
                placeholder="Your email address"
              />
              <button className="absolute right-6 top-4 ">
                <img
                  src="/assets/icons/send.svg"
                  alt=""
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
